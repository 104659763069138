"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfOrganisation = instanceOfOrganisation;
exports.OrganisationFromJSON = OrganisationFromJSON;
exports.OrganisationFromJSONTyped = OrganisationFromJSONTyped;
exports.OrganisationToJSON = OrganisationToJSON;
var runtime_1 = require("../runtime");
var Address_1 = require("./Address");
var AllThirdPartyEditableOrganisationDetails_1 = require("./AllThirdPartyEditableOrganisationDetails");
var CustomerLogo_1 = require("./CustomerLogo");
var Link_1 = require("./Link");
var Offset_1 = require("./Offset");
var RegisteredAddress_1 = require("./RegisteredAddress");
var SearchScreenTitlePosition_1 = require("./SearchScreenTitlePosition");
var SupportContactDetails_1 = require("./SupportContactDetails");
/**
 * Check if a given object implements the Organisation interface.
 */
function instanceOfOrganisation(value) {
    var isInstance = true;
    isInstance = isInstance && "readableReference" in value;
    isInstance = isInstance && "registeredName" in value;
    isInstance = isInstance && "urlName" in value;
    isInstance = isInstance && "address" in value;
    isInstance = isInstance && "tabTitle" in value;
    isInstance = isInstance && "homepageUrl" in value;
    isInstance = isInstance && "showEnterpriseLandingLink" in value;
    isInstance = isInstance && "includeEScooterRoutes" in value;
    isInstance = isInstance && "bookingAllowed" in value;
    isInstance = isInstance && "originPlaceholder" in value;
    isInstance = isInstance && "destPlaceholder" in value;
    isInstance = isInstance && "id" in value;
    return isInstance;
}
function OrganisationFromJSON(json) {
    return OrganisationFromJSONTyped(json, false);
}
function OrganisationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'readableReference': json['readableReference'],
        'registeredName': json['registeredName'],
        'urlName': json['urlName'],
        'address': (0, RegisteredAddress_1.RegisteredAddressFromJSON)(json['address']),
        'tabTitle': json['tabTitle'],
        'homepageUrl': json['homepageUrl'],
        'homepageImageOffset': !(0, runtime_1.exists)(json, 'homepageImageOffset') ? undefined : (0, Offset_1.OffsetFromJSON)(json['homepageImageOffset']),
        'searchScreenTitlePosition': !(0, runtime_1.exists)(json, 'searchScreenTitlePosition') ? undefined : (0, SearchScreenTitlePosition_1.SearchScreenTitlePositionFromJSON)(json['searchScreenTitlePosition']),
        'customerLogo': !(0, runtime_1.exists)(json, 'customerLogo') ? undefined : (0, CustomerLogo_1.CustomerLogoFromJSON)(json['customerLogo']),
        'routeZeroLogoColor': !(0, runtime_1.exists)(json, 'routeZeroLogoColor') ? undefined : json['routeZeroLogoColor'],
        'link': !(0, runtime_1.exists)(json, 'link') ? undefined : (0, Link_1.LinkFromJSON)(json['link']),
        'contact': !(0, runtime_1.exists)(json, 'contact') ? undefined : (0, SupportContactDetails_1.SupportContactDetailsFromJSON)(json['contact']),
        'showEnterpriseLandingLink': json['showEnterpriseLandingLink'],
        'includeEScooterRoutes': json['includeEScooterRoutes'],
        'bookingAllowed': json['bookingAllowed'],
        'originPlaceholder': (0, Address_1.AddressFromJSON)(json['originPlaceholder']),
        'destPlaceholder': (0, Address_1.AddressFromJSON)(json['destPlaceholder']),
        'id': json['id'],
        'thirdPartyDetails': !(0, runtime_1.exists)(json, 'thirdPartyDetails') ? undefined : (0, AllThirdPartyEditableOrganisationDetails_1.AllThirdPartyEditableOrganisationDetailsFromJSON)(json['thirdPartyDetails']),
    };
}
function OrganisationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'readableReference': value.readableReference,
        'registeredName': value.registeredName,
        'urlName': value.urlName,
        'address': (0, RegisteredAddress_1.RegisteredAddressToJSON)(value.address),
        'tabTitle': value.tabTitle,
        'homepageUrl': value.homepageUrl,
        'homepageImageOffset': (0, Offset_1.OffsetToJSON)(value.homepageImageOffset),
        'searchScreenTitlePosition': (0, SearchScreenTitlePosition_1.SearchScreenTitlePositionToJSON)(value.searchScreenTitlePosition),
        'customerLogo': (0, CustomerLogo_1.CustomerLogoToJSON)(value.customerLogo),
        'routeZeroLogoColor': value.routeZeroLogoColor,
        'link': (0, Link_1.LinkToJSON)(value.link),
        'contact': (0, SupportContactDetails_1.SupportContactDetailsToJSON)(value.contact),
        'showEnterpriseLandingLink': value.showEnterpriseLandingLink,
        'includeEScooterRoutes': value.includeEScooterRoutes,
        'bookingAllowed': value.bookingAllowed,
        'originPlaceholder': (0, Address_1.AddressToJSON)(value.originPlaceholder),
        'destPlaceholder': (0, Address_1.AddressToJSON)(value.destPlaceholder),
        'id': value.id,
        'thirdPartyDetails': (0, AllThirdPartyEditableOrganisationDetails_1.AllThirdPartyEditableOrganisationDetailsToJSON)(value.thirdPartyDetails),
    };
}
