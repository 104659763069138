"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderCompleteEventType = void 0;
exports.OrderCompleteEventTypeFromJSON = OrderCompleteEventTypeFromJSON;
exports.OrderCompleteEventTypeFromJSONTyped = OrderCompleteEventTypeFromJSONTyped;
exports.OrderCompleteEventTypeToJSON = OrderCompleteEventTypeToJSON;
/**
 *
 * @export
 */
exports.OrderCompleteEventType = {
    OrderComplete: 'orderComplete'
};
function OrderCompleteEventTypeFromJSON(json) {
    return OrderCompleteEventTypeFromJSONTyped(json, false);
}
function OrderCompleteEventTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function OrderCompleteEventTypeToJSON(value) {
    return value;
}
