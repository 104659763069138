"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTicketsReadyEvent = instanceOfTicketsReadyEvent;
exports.TicketsReadyEventFromJSON = TicketsReadyEventFromJSON;
exports.TicketsReadyEventFromJSONTyped = TicketsReadyEventFromJSONTyped;
exports.TicketsReadyEventToJSON = TicketsReadyEventToJSON;
var BookingInfo_1 = require("./BookingInfo");
var IntendedBookingService_1 = require("./IntendedBookingService");
var Ticket_1 = require("./Ticket");
var TicketsReadyEventType_1 = require("./TicketsReadyEventType");
/**
 * Check if a given object implements the TicketsReadyEvent interface.
 */
function instanceOfTicketsReadyEvent(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "lookupId" in value;
    isInstance = isInstance && "recipientEmail" in value;
    isInstance = isInstance && "readableOrderReference" in value;
    isInstance = isInstance && "tickets" in value;
    isInstance = isInstance && "bookingInfo" in value;
    isInstance = isInstance && "thirdPartyBookingProvider" in value;
    return isInstance;
}
function TicketsReadyEventFromJSON(json) {
    return TicketsReadyEventFromJSONTyped(json, false);
}
function TicketsReadyEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TicketsReadyEventType_1.TicketsReadyEventTypeFromJSON)(json['type']),
        'lookupId': json['lookupId'],
        'recipientEmail': json['recipientEmail'],
        'readableOrderReference': json['readableOrderReference'],
        'tickets': (json['tickets'].map(Ticket_1.TicketFromJSON)),
        'bookingInfo': (0, BookingInfo_1.BookingInfoFromJSON)(json['bookingInfo']),
        'thirdPartyBookingProvider': (0, IntendedBookingService_1.IntendedBookingServiceFromJSON)(json['thirdPartyBookingProvider']),
    };
}
function TicketsReadyEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TicketsReadyEventType_1.TicketsReadyEventTypeToJSON)(value.type),
        'lookupId': value.lookupId,
        'recipientEmail': value.recipientEmail,
        'readableOrderReference': value.readableOrderReference,
        'tickets': (value.tickets.map(Ticket_1.TicketToJSON)),
        'bookingInfo': (0, BookingInfo_1.BookingInfoToJSON)(value.bookingInfo),
        'thirdPartyBookingProvider': (0, IntendedBookingService_1.IntendedBookingServiceToJSON)(value.thirdPartyBookingProvider),
    };
}
