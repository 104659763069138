"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookingCheckoutDetails = instanceOfBookingCheckoutDetails;
exports.BookingCheckoutDetailsFromJSON = BookingCheckoutDetailsFromJSON;
exports.BookingCheckoutDetailsFromJSONTyped = BookingCheckoutDetailsFromJSONTyped;
exports.BookingCheckoutDetailsToJSON = BookingCheckoutDetailsToJSON;
var runtime_1 = require("../runtime");
var BookingJourneys_1 = require("./BookingJourneys");
var BookingPricing_1 = require("./BookingPricing");
var LegalNotice_1 = require("./LegalNotice");
var TravellerLoyaltyProgramme_1 = require("./TravellerLoyaltyProgramme");
/**
 * Check if a given object implements the BookingCheckoutDetails interface.
 */
function instanceOfBookingCheckoutDetails(value) {
    var isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "pricing" in value;
    isInstance = isInstance && "journeys" in value;
    isInstance = isInstance && "appliedLoyaltyProgrammes" in value;
    isInstance = isInstance && "legalNotice" in value;
    return isInstance;
}
function BookingCheckoutDetailsFromJSON(json) {
    return BookingCheckoutDetailsFromJSONTyped(json, false);
}
function BookingCheckoutDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'readableOrderReference': !(0, runtime_1.exists)(json, 'readableOrderReference') ? undefined : json['readableOrderReference'],
        'pricing': (0, BookingPricing_1.BookingPricingFromJSON)(json['pricing']),
        'journeys': (0, BookingJourneys_1.BookingJourneysFromJSON)(json['journeys']),
        'appliedLoyaltyProgrammes': (json['appliedLoyaltyProgrammes'].map(TravellerLoyaltyProgramme_1.TravellerLoyaltyProgrammeFromJSON)),
        'legalNotice': (0, LegalNotice_1.LegalNoticeFromJSON)(json['legalNotice']),
    };
}
function BookingCheckoutDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'readableOrderReference': value.readableOrderReference,
        'pricing': (0, BookingPricing_1.BookingPricingToJSON)(value.pricing),
        'journeys': (0, BookingJourneys_1.BookingJourneysToJSON)(value.journeys),
        'appliedLoyaltyProgrammes': (value.appliedLoyaltyProgrammes.map(TravellerLoyaltyProgramme_1.TravellerLoyaltyProgrammeToJSON)),
        'legalNotice': (0, LegalNotice_1.LegalNoticeToJSON)(value.legalNotice),
    };
}
