"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TicketsFailedEventType = void 0;
exports.TicketsFailedEventTypeFromJSON = TicketsFailedEventTypeFromJSON;
exports.TicketsFailedEventTypeFromJSONTyped = TicketsFailedEventTypeFromJSONTyped;
exports.TicketsFailedEventTypeToJSON = TicketsFailedEventTypeToJSON;
/**
 *
 * @export
 */
exports.TicketsFailedEventType = {
    TicketsFailed: 'ticketsFailed'
};
function TicketsFailedEventTypeFromJSON(json) {
    return TicketsFailedEventTypeFromJSONTyped(json, false);
}
function TicketsFailedEventTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function TicketsFailedEventTypeToJSON(value) {
    return value;
}
