"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPrice = instanceOfPrice;
exports.PriceFromJSON = PriceFromJSON;
exports.PriceFromJSONTyped = PriceFromJSONTyped;
exports.PriceToJSON = PriceToJSON;
/**
 * Check if a given object implements the Price interface.
 */
function instanceOfPrice(value) {
    var isInstance = true;
    isInstance = isInstance && "currencyCode" in value;
    isInstance = isInstance && "value" in value;
    return isInstance;
}
function PriceFromJSON(json) {
    return PriceFromJSONTyped(json, false);
}
function PriceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'currencyCode': json['currencyCode'],
        'value': json['value'],
    };
}
function PriceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'currencyCode': value.currencyCode,
        'value': value.value,
    };
}
