"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DiscountReasonFromJSON = DiscountReasonFromJSON;
exports.DiscountReasonFromJSONTyped = DiscountReasonFromJSONTyped;
exports.DiscountReasonToJSON = DiscountReasonToJSON;
var DiscountCardReason_1 = require("./DiscountCardReason");
var GroupSaveReason_1 = require("./GroupSaveReason");
var SplitTicketReason_1 = require("./SplitTicketReason");
function DiscountReasonFromJSON(json) {
    return DiscountReasonFromJSONTyped(json, false);
}
function DiscountReasonFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'discountCard':
            return __assign(__assign({}, (0, DiscountCardReason_1.DiscountCardReasonFromJSONTyped)(json, true)), { type: 'discountCard' });
        case 'groupSave':
            return __assign(__assign({}, (0, GroupSaveReason_1.GroupSaveReasonFromJSONTyped)(json, true)), { type: 'groupSave' });
        case 'splitTicket':
            return __assign(__assign({}, (0, SplitTicketReason_1.SplitTicketReasonFromJSONTyped)(json, true)), { type: 'splitTicket' });
        default:
            throw new Error("No variant of DiscountReason exists with 'type=".concat(json['type'], "'"));
    }
}
function DiscountReasonToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'discountCard':
            return (0, DiscountCardReason_1.DiscountCardReasonToJSON)(value);
        case 'groupSave':
            return (0, GroupSaveReason_1.GroupSaveReasonToJSON)(value);
        case 'splitTicket':
            return (0, SplitTicketReason_1.SplitTicketReasonToJSON)(value);
        default:
            throw new Error("No variant of DiscountReason exists with 'type=".concat(value['type'], "'"));
    }
}
