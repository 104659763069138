"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.OrderFailedEventType = void 0;
exports.OrderFailedEventTypeFromJSON = OrderFailedEventTypeFromJSON;
exports.OrderFailedEventTypeFromJSONTyped = OrderFailedEventTypeFromJSONTyped;
exports.OrderFailedEventTypeToJSON = OrderFailedEventTypeToJSON;
/**
 *
 * @export
 */
exports.OrderFailedEventType = {
    OrderFailed: 'orderFailed'
};
function OrderFailedEventTypeFromJSON(json) {
    return OrderFailedEventTypeFromJSONTyped(json, false);
}
function OrderFailedEventTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function OrderFailedEventTypeToJSON(value) {
    return value;
}
