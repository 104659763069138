"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTravelProviderBookingPriceStrategies = instanceOfTravelProviderBookingPriceStrategies;
exports.TravelProviderBookingPriceStrategiesFromJSON = TravelProviderBookingPriceStrategiesFromJSON;
exports.TravelProviderBookingPriceStrategiesFromJSONTyped = TravelProviderBookingPriceStrategiesFromJSONTyped;
exports.TravelProviderBookingPriceStrategiesToJSON = TravelProviderBookingPriceStrategiesToJSON;
var runtime_1 = require("../runtime");
var DuffelBookingPriceStrategies_1 = require("./DuffelBookingPriceStrategies");
var TrainlineBookingPriceStrategies_1 = require("./TrainlineBookingPriceStrategies");
/**
 * Check if a given object implements the TravelProviderBookingPriceStrategies interface.
 */
function instanceOfTravelProviderBookingPriceStrategies(value) {
    var isInstance = true;
    return isInstance;
}
function TravelProviderBookingPriceStrategiesFromJSON(json) {
    return TravelProviderBookingPriceStrategiesFromJSONTyped(json, false);
}
function TravelProviderBookingPriceStrategiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'trainline': !(0, runtime_1.exists)(json, 'trainline') ? undefined : (0, TrainlineBookingPriceStrategies_1.TrainlineBookingPriceStrategiesFromJSON)(json['trainline']),
        'duffel': !(0, runtime_1.exists)(json, 'duffel') ? undefined : (0, DuffelBookingPriceStrategies_1.DuffelBookingPriceStrategiesFromJSON)(json['duffel']),
    };
}
function TravelProviderBookingPriceStrategiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'trainline': (0, TrainlineBookingPriceStrategies_1.TrainlineBookingPriceStrategiesToJSON)(value.trainline),
        'duffel': (0, DuffelBookingPriceStrategies_1.DuffelBookingPriceStrategiesToJSON)(value.duffel),
    };
}
