"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTrainlineBookingPriceStrategies = instanceOfTrainlineBookingPriceStrategies;
exports.TrainlineBookingPriceStrategiesFromJSON = TrainlineBookingPriceStrategiesFromJSON;
exports.TrainlineBookingPriceStrategiesFromJSONTyped = TrainlineBookingPriceStrategiesFromJSONTyped;
exports.TrainlineBookingPriceStrategiesToJSON = TrainlineBookingPriceStrategiesToJSON;
var runtime_1 = require("../runtime");
var MarkupStrategy_1 = require("./MarkupStrategy");
var SubsidyStrategy_1 = require("./SubsidyStrategy");
var TrainlineFeeLocation_1 = require("./TrainlineFeeLocation");
var ValueAdjustment_1 = require("./ValueAdjustment");
/**
 * Check if a given object implements the TrainlineBookingPriceStrategies interface.
 */
function instanceOfTrainlineBookingPriceStrategies(value) {
    var isInstance = true;
    isInstance = isInstance && "markup" in value;
    isInstance = isInstance && "subsidy" in value;
    isInstance = isInstance && "paymentProviderFeesLocation" in value;
    isInstance = isInstance && "travelProviderFeesLocation" in value;
    return isInstance;
}
function TrainlineBookingPriceStrategiesFromJSON(json) {
    return TrainlineBookingPriceStrategiesFromJSONTyped(json, false);
}
function TrainlineBookingPriceStrategiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'markup': (0, MarkupStrategy_1.MarkupStrategyFromJSON)(json['markup']),
        'subsidy': (0, SubsidyStrategy_1.SubsidyStrategyFromJSON)(json['subsidy']),
        'maxUndercut': !(0, runtime_1.exists)(json, 'maxUndercut') ? undefined : (0, ValueAdjustment_1.ValueAdjustmentFromJSON)(json['maxUndercut']),
        'paymentProviderFeesLocation': (0, TrainlineFeeLocation_1.TrainlineFeeLocationFromJSON)(json['paymentProviderFeesLocation']),
        'travelProviderFeesLocation': (0, TrainlineFeeLocation_1.TrainlineFeeLocationFromJSON)(json['travelProviderFeesLocation']),
    };
}
function TrainlineBookingPriceStrategiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'markup': (0, MarkupStrategy_1.MarkupStrategyToJSON)(value.markup),
        'subsidy': (0, SubsidyStrategy_1.SubsidyStrategyToJSON)(value.subsidy),
        'maxUndercut': (0, ValueAdjustment_1.ValueAdjustmentToJSON)(value.maxUndercut),
        'paymentProviderFeesLocation': (0, TrainlineFeeLocation_1.TrainlineFeeLocationToJSON)(value.paymentProviderFeesLocation),
        'travelProviderFeesLocation': (0, TrainlineFeeLocation_1.TrainlineFeeLocationToJSON)(value.travelProviderFeesLocation),
    };
}
