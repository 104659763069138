"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingChoiceFromJSON = BookingChoiceFromJSON;
exports.BookingChoiceFromJSONTyped = BookingChoiceFromJSONTyped;
exports.BookingChoiceToJSON = BookingChoiceToJSON;
var BookerDetailsBookingChoice_1 = require("./BookerDetailsBookingChoice");
var FlightSeatingOptionsBookingChoice_1 = require("./FlightSeatingOptionsBookingChoice");
var PassengerDetailsBookingChoice_1 = require("./PassengerDetailsBookingChoice");
var TicketCollectionTypeBookingChoice_1 = require("./TicketCollectionTypeBookingChoice");
var TrainSeatingOptionsBookingChoice_1 = require("./TrainSeatingOptionsBookingChoice");
function BookingChoiceFromJSON(json) {
    return BookingChoiceFromJSONTyped(json, false);
}
function BookingChoiceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'bookerDetails':
            return __assign(__assign({}, (0, BookerDetailsBookingChoice_1.BookerDetailsBookingChoiceFromJSONTyped)(json, true)), { type: 'bookerDetails' });
        case 'flightSeatingOptions':
            return __assign(__assign({}, (0, FlightSeatingOptionsBookingChoice_1.FlightSeatingOptionsBookingChoiceFromJSONTyped)(json, true)), { type: 'flightSeatingOptions' });
        case 'passengerDetails':
            return __assign(__assign({}, (0, PassengerDetailsBookingChoice_1.PassengerDetailsBookingChoiceFromJSONTyped)(json, true)), { type: 'passengerDetails' });
        case 'ticketCollectionType':
            return __assign(__assign({}, (0, TicketCollectionTypeBookingChoice_1.TicketCollectionTypeBookingChoiceFromJSONTyped)(json, true)), { type: 'ticketCollectionType' });
        case 'trainSeatingOptions':
            return __assign(__assign({}, (0, TrainSeatingOptionsBookingChoice_1.TrainSeatingOptionsBookingChoiceFromJSONTyped)(json, true)), { type: 'trainSeatingOptions' });
        default:
            throw new Error("No variant of BookingChoice exists with 'type=".concat(json['type'], "'"));
    }
}
function BookingChoiceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'bookerDetails':
            return (0, BookerDetailsBookingChoice_1.BookerDetailsBookingChoiceToJSON)(value);
        case 'flightSeatingOptions':
            return (0, FlightSeatingOptionsBookingChoice_1.FlightSeatingOptionsBookingChoiceToJSON)(value);
        case 'passengerDetails':
            return (0, PassengerDetailsBookingChoice_1.PassengerDetailsBookingChoiceToJSON)(value);
        case 'ticketCollectionType':
            return (0, TicketCollectionTypeBookingChoice_1.TicketCollectionTypeBookingChoiceToJSON)(value);
        case 'trainSeatingOptions':
            return (0, TrainSeatingOptionsBookingChoice_1.TrainSeatingOptionsBookingChoiceToJSON)(value);
        default:
            throw new Error("No variant of BookingChoice exists with 'type=".concat(value['type'], "'"));
    }
}
