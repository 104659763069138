"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfTicketsSentEvent = instanceOfTicketsSentEvent;
exports.TicketsSentEventFromJSON = TicketsSentEventFromJSON;
exports.TicketsSentEventFromJSONTyped = TicketsSentEventFromJSONTyped;
exports.TicketsSentEventToJSON = TicketsSentEventToJSON;
var TicketsSentEventType_1 = require("./TicketsSentEventType");
/**
 * Check if a given object implements the TicketsSentEvent interface.
 */
function instanceOfTicketsSentEvent(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "lookupId" in value;
    return isInstance;
}
function TicketsSentEventFromJSON(json) {
    return TicketsSentEventFromJSONTyped(json, false);
}
function TicketsSentEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, TicketsSentEventType_1.TicketsSentEventTypeFromJSON)(json['type']),
        'lookupId': json['lookupId'],
    };
}
function TicketsSentEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, TicketsSentEventType_1.TicketsSentEventTypeToJSON)(value.type),
        'lookupId': value.lookupId,
    };
}
