"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BookingLineItemFromJSON = BookingLineItemFromJSON;
exports.BookingLineItemFromJSONTyped = BookingLineItemFromJSONTyped;
exports.BookingLineItemToJSON = BookingLineItemToJSON;
var AirFareLineItem_1 = require("./AirFareLineItem");
var BookingFeeLineItem_1 = require("./BookingFeeLineItem");
var DeliveryFeeLineItem_1 = require("./DeliveryFeeLineItem");
var FareTaxLineItem_1 = require("./FareTaxLineItem");
var PaymentFeeLineItem_1 = require("./PaymentFeeLineItem");
var RailFareLineItem_1 = require("./RailFareLineItem");
var SeatingLineItem_1 = require("./SeatingLineItem");
function BookingLineItemFromJSON(json) {
    return BookingLineItemFromJSONTyped(json, false);
}
function BookingLineItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    switch (json['type']) {
        case 'airFare':
            return __assign(__assign({}, (0, AirFareLineItem_1.AirFareLineItemFromJSONTyped)(json, true)), { type: 'airFare' });
        case 'bookingFee':
            return __assign(__assign({}, (0, BookingFeeLineItem_1.BookingFeeLineItemFromJSONTyped)(json, true)), { type: 'bookingFee' });
        case 'deliveryFee':
            return __assign(__assign({}, (0, DeliveryFeeLineItem_1.DeliveryFeeLineItemFromJSONTyped)(json, true)), { type: 'deliveryFee' });
        case 'fareTax':
            return __assign(__assign({}, (0, FareTaxLineItem_1.FareTaxLineItemFromJSONTyped)(json, true)), { type: 'fareTax' });
        case 'paymentFee':
            return __assign(__assign({}, (0, PaymentFeeLineItem_1.PaymentFeeLineItemFromJSONTyped)(json, true)), { type: 'paymentFee' });
        case 'railFare':
            return __assign(__assign({}, (0, RailFareLineItem_1.RailFareLineItemFromJSONTyped)(json, true)), { type: 'railFare' });
        case 'seating':
            return __assign(__assign({}, (0, SeatingLineItem_1.SeatingLineItemFromJSONTyped)(json, true)), { type: 'seating' });
        default:
            throw new Error("No variant of BookingLineItem exists with 'type=".concat(json['type'], "'"));
    }
}
function BookingLineItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    switch (value['type']) {
        case 'airFare':
            return (0, AirFareLineItem_1.AirFareLineItemToJSON)(value);
        case 'bookingFee':
            return (0, BookingFeeLineItem_1.BookingFeeLineItemToJSON)(value);
        case 'deliveryFee':
            return (0, DeliveryFeeLineItem_1.DeliveryFeeLineItemToJSON)(value);
        case 'fareTax':
            return (0, FareTaxLineItem_1.FareTaxLineItemToJSON)(value);
        case 'paymentFee':
            return (0, PaymentFeeLineItem_1.PaymentFeeLineItemToJSON)(value);
        case 'railFare':
            return (0, RailFareLineItem_1.RailFareLineItemToJSON)(value);
        case 'seating':
            return (0, SeatingLineItem_1.SeatingLineItemToJSON)(value);
        default:
            throw new Error("No variant of BookingLineItem exists with 'type=".concat(value['type'], "'"));
    }
}
