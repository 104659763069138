"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentCancelFailedEventType = void 0;
exports.PaymentCancelFailedEventTypeFromJSON = PaymentCancelFailedEventTypeFromJSON;
exports.PaymentCancelFailedEventTypeFromJSONTyped = PaymentCancelFailedEventTypeFromJSONTyped;
exports.PaymentCancelFailedEventTypeToJSON = PaymentCancelFailedEventTypeToJSON;
/**
 *
 * @export
 */
exports.PaymentCancelFailedEventType = {
    PaymentCancelFailed: 'paymentCancelFailed'
};
function PaymentCancelFailedEventTypeFromJSON(json) {
    return PaymentCancelFailedEventTypeFromJSONTyped(json, false);
}
function PaymentCancelFailedEventTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function PaymentCancelFailedEventTypeToJSON(value) {
    return value;
}
