"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPaymentHeldEvent = instanceOfPaymentHeldEvent;
exports.PaymentHeldEventFromJSON = PaymentHeldEventFromJSON;
exports.PaymentHeldEventFromJSONTyped = PaymentHeldEventFromJSONTyped;
exports.PaymentHeldEventToJSON = PaymentHeldEventToJSON;
var IntendedBookingService_1 = require("./IntendedBookingService");
var PaymentHeldEventType_1 = require("./PaymentHeldEventType");
/**
 * Check if a given object implements the PaymentHeldEvent interface.
 */
function instanceOfPaymentHeldEvent(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "lookupId" in value;
    isInstance = isInstance && "intendedBookingService" in value;
    return isInstance;
}
function PaymentHeldEventFromJSON(json) {
    return PaymentHeldEventFromJSONTyped(json, false);
}
function PaymentHeldEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, PaymentHeldEventType_1.PaymentHeldEventTypeFromJSON)(json['type']),
        'lookupId': json['lookupId'],
        'intendedBookingService': (0, IntendedBookingService_1.IntendedBookingServiceFromJSON)(json['intendedBookingService']),
    };
}
function PaymentHeldEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, PaymentHeldEventType_1.PaymentHeldEventTypeToJSON)(value.type),
        'lookupId': value.lookupId,
        'intendedBookingService': (0, IntendedBookingService_1.IntendedBookingServiceToJSON)(value.intendedBookingService),
    };
}
