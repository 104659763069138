"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookingLocation = instanceOfBookingLocation;
exports.BookingLocationFromJSON = BookingLocationFromJSON;
exports.BookingLocationFromJSONTyped = BookingLocationFromJSONTyped;
exports.BookingLocationToJSON = BookingLocationToJSON;
var runtime_1 = require("../runtime");
var Timezone_1 = require("./Timezone");
/**
 * Check if a given object implements the BookingLocation interface.
 */
function instanceOfBookingLocation(value) {
    var isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "timezone" in value;
    return isInstance;
}
function BookingLocationFromJSON(json) {
    return BookingLocationFromJSONTyped(json, false);
}
function BookingLocationFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'timezone': (0, Timezone_1.TimezoneFromJSON)(json['timezone']),
        'terminal': !(0, runtime_1.exists)(json, 'terminal') ? undefined : json['terminal'],
    };
}
function BookingLocationToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'timezone': (0, Timezone_1.TimezoneToJSON)(value.timezone),
        'terminal': value.terminal,
    };
}
