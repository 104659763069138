"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfPaymentCaptureFailedEvent = instanceOfPaymentCaptureFailedEvent;
exports.PaymentCaptureFailedEventFromJSON = PaymentCaptureFailedEventFromJSON;
exports.PaymentCaptureFailedEventFromJSONTyped = PaymentCaptureFailedEventFromJSONTyped;
exports.PaymentCaptureFailedEventToJSON = PaymentCaptureFailedEventToJSON;
var PaymentCaptureFailedEventType_1 = require("./PaymentCaptureFailedEventType");
/**
 * Check if a given object implements the PaymentCaptureFailedEvent interface.
 */
function instanceOfPaymentCaptureFailedEvent(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "lookupId" in value;
    return isInstance;
}
function PaymentCaptureFailedEventFromJSON(json) {
    return PaymentCaptureFailedEventFromJSONTyped(json, false);
}
function PaymentCaptureFailedEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, PaymentCaptureFailedEventType_1.PaymentCaptureFailedEventTypeFromJSON)(json['type']),
        'lookupId': json['lookupId'],
    };
}
function PaymentCaptureFailedEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, PaymentCaptureFailedEventType_1.PaymentCaptureFailedEventTypeToJSON)(value.type),
        'lookupId': value.lookupId,
    };
}
