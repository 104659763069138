"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookingPriceStrategies = instanceOfBookingPriceStrategies;
exports.BookingPriceStrategiesFromJSON = BookingPriceStrategiesFromJSON;
exports.BookingPriceStrategiesFromJSONTyped = BookingPriceStrategiesFromJSONTyped;
exports.BookingPriceStrategiesToJSON = BookingPriceStrategiesToJSON;
var runtime_1 = require("../runtime");
var MarkupStrategy_1 = require("./MarkupStrategy");
var SubsidyStrategy_1 = require("./SubsidyStrategy");
var ValueAdjustment_1 = require("./ValueAdjustment");
/**
 * Check if a given object implements the BookingPriceStrategies interface.
 */
function instanceOfBookingPriceStrategies(value) {
    var isInstance = true;
    isInstance = isInstance && "markup" in value;
    isInstance = isInstance && "subsidy" in value;
    return isInstance;
}
function BookingPriceStrategiesFromJSON(json) {
    return BookingPriceStrategiesFromJSONTyped(json, false);
}
function BookingPriceStrategiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'markup': (0, MarkupStrategy_1.MarkupStrategyFromJSON)(json['markup']),
        'subsidy': (0, SubsidyStrategy_1.SubsidyStrategyFromJSON)(json['subsidy']),
        'maxUndercut': !(0, runtime_1.exists)(json, 'maxUndercut') ? undefined : (0, ValueAdjustment_1.ValueAdjustmentFromJSON)(json['maxUndercut']),
    };
}
function BookingPriceStrategiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'markup': (0, MarkupStrategy_1.MarkupStrategyToJSON)(value.markup),
        'subsidy': (0, SubsidyStrategy_1.SubsidyStrategyToJSON)(value.subsidy),
        'maxUndercut': (0, ValueAdjustment_1.ValueAdjustmentToJSON)(value.maxUndercut),
    };
}
