"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfNullableEditableOrganisationDetails = instanceOfNullableEditableOrganisationDetails;
exports.NullableEditableOrganisationDetailsFromJSON = NullableEditableOrganisationDetailsFromJSON;
exports.NullableEditableOrganisationDetailsFromJSONTyped = NullableEditableOrganisationDetailsFromJSONTyped;
exports.NullableEditableOrganisationDetailsToJSON = NullableEditableOrganisationDetailsToJSON;
var runtime_1 = require("../runtime");
var Address_1 = require("./Address");
var NullableCustomerLogo_1 = require("./NullableCustomerLogo");
var NullableLink_1 = require("./NullableLink");
var NullableOffset_1 = require("./NullableOffset");
var NullableSupportContactDetails_1 = require("./NullableSupportContactDetails");
var RegisteredAddress_1 = require("./RegisteredAddress");
var SearchScreenTitlePosition_1 = require("./SearchScreenTitlePosition");
/**
 * Check if a given object implements the NullableEditableOrganisationDetails interface.
 */
function instanceOfNullableEditableOrganisationDetails(value) {
    var isInstance = true;
    return isInstance;
}
function NullableEditableOrganisationDetailsFromJSON(json) {
    return NullableEditableOrganisationDetailsFromJSONTyped(json, false);
}
function NullableEditableOrganisationDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'readableReference': !(0, runtime_1.exists)(json, 'readableReference') ? undefined : json['readableReference'],
        'registeredName': !(0, runtime_1.exists)(json, 'registeredName') ? undefined : json['registeredName'],
        'urlName': !(0, runtime_1.exists)(json, 'urlName') ? undefined : json['urlName'],
        'address': !(0, runtime_1.exists)(json, 'address') ? undefined : (0, RegisteredAddress_1.RegisteredAddressFromJSON)(json['address']),
        'tabTitle': !(0, runtime_1.exists)(json, 'tabTitle') ? undefined : json['tabTitle'],
        'homepageUrl': !(0, runtime_1.exists)(json, 'homepageUrl') ? undefined : json['homepageUrl'],
        'homepageImageOffset': !(0, runtime_1.exists)(json, 'homepageImageOffset') ? undefined : (0, NullableOffset_1.NullableOffsetFromJSON)(json['homepageImageOffset']),
        'searchScreenTitlePosition': !(0, runtime_1.exists)(json, 'searchScreenTitlePosition') ? undefined : (0, SearchScreenTitlePosition_1.SearchScreenTitlePositionFromJSON)(json['searchScreenTitlePosition']),
        'customerLogo': !(0, runtime_1.exists)(json, 'customerLogo') ? undefined : (0, NullableCustomerLogo_1.NullableCustomerLogoFromJSON)(json['customerLogo']),
        'routeZeroLogoColor': !(0, runtime_1.exists)(json, 'routeZeroLogoColor') ? undefined : json['routeZeroLogoColor'],
        'link': !(0, runtime_1.exists)(json, 'link') ? undefined : (0, NullableLink_1.NullableLinkFromJSON)(json['link']),
        'contact': !(0, runtime_1.exists)(json, 'contact') ? undefined : (0, NullableSupportContactDetails_1.NullableSupportContactDetailsFromJSON)(json['contact']),
        'showEnterpriseLandingLink': !(0, runtime_1.exists)(json, 'showEnterpriseLandingLink') ? undefined : json['showEnterpriseLandingLink'],
        'includeEScooterRoutes': !(0, runtime_1.exists)(json, 'includeEScooterRoutes') ? undefined : json['includeEScooterRoutes'],
        'bookingAllowed': !(0, runtime_1.exists)(json, 'bookingAllowed') ? undefined : json['bookingAllowed'],
        'originPlaceholder': !(0, runtime_1.exists)(json, 'originPlaceholder') ? undefined : (0, Address_1.AddressFromJSON)(json['originPlaceholder']),
        'destPlaceholder': !(0, runtime_1.exists)(json, 'destPlaceholder') ? undefined : (0, Address_1.AddressFromJSON)(json['destPlaceholder']),
    };
}
function NullableEditableOrganisationDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'readableReference': value.readableReference,
        'registeredName': value.registeredName,
        'urlName': value.urlName,
        'address': (0, RegisteredAddress_1.RegisteredAddressToJSON)(value.address),
        'tabTitle': value.tabTitle,
        'homepageUrl': value.homepageUrl,
        'homepageImageOffset': (0, NullableOffset_1.NullableOffsetToJSON)(value.homepageImageOffset),
        'searchScreenTitlePosition': (0, SearchScreenTitlePosition_1.SearchScreenTitlePositionToJSON)(value.searchScreenTitlePosition),
        'customerLogo': (0, NullableCustomerLogo_1.NullableCustomerLogoToJSON)(value.customerLogo),
        'routeZeroLogoColor': value.routeZeroLogoColor,
        'link': (0, NullableLink_1.NullableLinkToJSON)(value.link),
        'contact': (0, NullableSupportContactDetails_1.NullableSupportContactDetailsToJSON)(value.contact),
        'showEnterpriseLandingLink': value.showEnterpriseLandingLink,
        'includeEScooterRoutes': value.includeEScooterRoutes,
        'bookingAllowed': value.bookingAllowed,
        'originPlaceholder': (0, Address_1.AddressToJSON)(value.originPlaceholder),
        'destPlaceholder': (0, Address_1.AddressToJSON)(value.destPlaceholder),
    };
}
