"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookingOpenReturnJourney = instanceOfBookingOpenReturnJourney;
exports.BookingOpenReturnJourneyFromJSON = BookingOpenReturnJourneyFromJSON;
exports.BookingOpenReturnJourneyFromJSONTyped = BookingOpenReturnJourneyFromJSONTyped;
exports.BookingOpenReturnJourneyToJSON = BookingOpenReturnJourneyToJSON;
var BookingLocation_1 = require("./BookingLocation");
var BookingOpenReturnJourneyType_1 = require("./BookingOpenReturnJourneyType");
var FormattedDate_1 = require("./FormattedDate");
/**
 * Check if a given object implements the BookingOpenReturnJourney interface.
 */
function instanceOfBookingOpenReturnJourney(value) {
    var isInstance = true;
    isInstance = isInstance && "type" in value;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "destination" in value;
    isInstance = isInstance && "validUntil" in value;
    return isInstance;
}
function BookingOpenReturnJourneyFromJSON(json) {
    return BookingOpenReturnJourneyFromJSONTyped(json, false);
}
function BookingOpenReturnJourneyFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'type': (0, BookingOpenReturnJourneyType_1.BookingOpenReturnJourneyTypeFromJSON)(json['type']),
        'origin': (0, BookingLocation_1.BookingLocationFromJSON)(json['origin']),
        'destination': (0, BookingLocation_1.BookingLocationFromJSON)(json['destination']),
        'validUntil': (0, FormattedDate_1.FormattedDateFromJSON)(json['validUntil']),
    };
}
function BookingOpenReturnJourneyToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'type': (0, BookingOpenReturnJourneyType_1.BookingOpenReturnJourneyTypeToJSON)(value.type),
        'origin': (0, BookingLocation_1.BookingLocationToJSON)(value.origin),
        'destination': (0, BookingLocation_1.BookingLocationToJSON)(value.destination),
        'validUntil': (0, FormattedDate_1.FormattedDateToJSON)(value.validUntil),
    };
}
