import { LinksService } from "@routezero-site/service/links/links_service";

/**
 * External links for use on the live site.
 */
export class LiveLinksService implements LinksService {
  rzRoutePlannerUrl(): string {
    return "https://routezero.world"
  }

  rzPrivacyPolicyUrl(): string {
    return 'https://docs.google.com/document/d/e/2PACX-1vTX1lY0JrhDs-WiHCl2wZyD_zSBxGy-W9LEw7k0q3PgzrvsPzZ-CvjvGBxZoiADqpIs_74BhNaV2c72/pub';
  }

  rzTermsAndConditionsUrl(): string {
    return 'https://docs.google.com/document/d/e/2PACX-1vSMF5F0rmoYMXMMgGd_fQnxmNAJSzpkliQAXMLuW6YSzjRA_Y4IGS_SKuPoamOwHwWm_v7wjyo_Mfnp/pub';
  }
}