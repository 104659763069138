"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.DuffelBookingMethodType = void 0;
exports.DuffelBookingMethodTypeFromJSON = DuffelBookingMethodTypeFromJSON;
exports.DuffelBookingMethodTypeFromJSONTyped = DuffelBookingMethodTypeFromJSONTyped;
exports.DuffelBookingMethodTypeToJSON = DuffelBookingMethodTypeToJSON;
/**
 *
 * @export
 */
exports.DuffelBookingMethodType = {
    Duffel: 'duffel'
};
function DuffelBookingMethodTypeFromJSON(json) {
    return DuffelBookingMethodTypeFromJSONTyped(json, false);
}
function DuffelBookingMethodTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function DuffelBookingMethodTypeToJSON(value) {
    return value;
}
