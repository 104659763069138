"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfNullableCustomerLogo = instanceOfNullableCustomerLogo;
exports.NullableCustomerLogoFromJSON = NullableCustomerLogoFromJSON;
exports.NullableCustomerLogoFromJSONTyped = NullableCustomerLogoFromJSONTyped;
exports.NullableCustomerLogoToJSON = NullableCustomerLogoToJSON;
var runtime_1 = require("../runtime");
/**
 * Check if a given object implements the NullableCustomerLogo interface.
 */
function instanceOfNullableCustomerLogo(value) {
    var isInstance = true;
    return isInstance;
}
function NullableCustomerLogoFromJSON(json) {
    return NullableCustomerLogoFromJSONTyped(json, false);
}
function NullableCustomerLogoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'url': !(0, runtime_1.exists)(json, 'url') ? undefined : json['url'],
        'scale': !(0, runtime_1.exists)(json, 'scale') ? undefined : json['scale'],
    };
}
function NullableCustomerLogoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'url': value.url,
        'scale': value.scale,
    };
}
