"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.TicketsSentEventType = void 0;
exports.TicketsSentEventTypeFromJSON = TicketsSentEventTypeFromJSON;
exports.TicketsSentEventTypeFromJSONTyped = TicketsSentEventTypeFromJSONTyped;
exports.TicketsSentEventTypeToJSON = TicketsSentEventTypeToJSON;
/**
 *
 * @export
 */
exports.TicketsSentEventType = {
    TicketsSent: 'ticketsSent'
};
function TicketsSentEventTypeFromJSON(json) {
    return TicketsSentEventTypeFromJSONTyped(json, false);
}
function TicketsSentEventTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function TicketsSentEventTypeToJSON(value) {
    return value;
}
