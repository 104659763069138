"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookingJourneyLeg = instanceOfBookingJourneyLeg;
exports.BookingJourneyLegFromJSON = BookingJourneyLegFromJSON;
exports.BookingJourneyLegFromJSONTyped = BookingJourneyLegFromJSONTyped;
exports.BookingJourneyLegToJSON = BookingJourneyLegToJSON;
var BaggageAllowance_1 = require("./BaggageAllowance");
var BookingLocation_1 = require("./BookingLocation");
var BookingSpaceAllocation_1 = require("./BookingSpaceAllocation");
var TimeRange_1 = require("./TimeRange");
var Transport_1 = require("./Transport");
var TravelClass_1 = require("./TravelClass");
/**
 * Check if a given object implements the BookingJourneyLeg interface.
 */
function instanceOfBookingJourneyLeg(value) {
    var isInstance = true;
    isInstance = isInstance && "origin" in value;
    isInstance = isInstance && "destination" in value;
    isInstance = isInstance && "transport" in value;
    isInstance = isInstance && "times" in value;
    isInstance = isInstance && "spaceAllocation" in value;
    isInstance = isInstance && "travelClasses" in value;
    isInstance = isInstance && "baggageAllowance" in value;
    return isInstance;
}
function BookingJourneyLegFromJSON(json) {
    return BookingJourneyLegFromJSONTyped(json, false);
}
function BookingJourneyLegFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'origin': (0, BookingLocation_1.BookingLocationFromJSON)(json['origin']),
        'destination': (0, BookingLocation_1.BookingLocationFromJSON)(json['destination']),
        'transport': (0, Transport_1.TransportFromJSON)(json['transport']),
        'times': (0, TimeRange_1.TimeRangeFromJSON)(json['times']),
        'spaceAllocation': (0, BookingSpaceAllocation_1.BookingSpaceAllocationFromJSON)(json['spaceAllocation']),
        'travelClasses': (json['travelClasses'].map(TravelClass_1.TravelClassFromJSON)),
        'baggageAllowance': (json['baggageAllowance'].map(BaggageAllowance_1.BaggageAllowanceFromJSON)),
    };
}
function BookingJourneyLegToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'origin': (0, BookingLocation_1.BookingLocationToJSON)(value.origin),
        'destination': (0, BookingLocation_1.BookingLocationToJSON)(value.destination),
        'transport': (0, Transport_1.TransportToJSON)(value.transport),
        'times': (0, TimeRange_1.TimeRangeToJSON)(value.times),
        'spaceAllocation': (0, BookingSpaceAllocation_1.BookingSpaceAllocationToJSON)(value.spaceAllocation),
        'travelClasses': (value.travelClasses.map(TravelClass_1.TravelClassToJSON)),
        'baggageAllowance': (value.baggageAllowance.map(BaggageAllowance_1.BaggageAllowanceToJSON)),
    };
}
