"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.PartialOutwardDuffelBookingMethodType = void 0;
exports.PartialOutwardDuffelBookingMethodTypeFromJSON = PartialOutwardDuffelBookingMethodTypeFromJSON;
exports.PartialOutwardDuffelBookingMethodTypeFromJSONTyped = PartialOutwardDuffelBookingMethodTypeFromJSONTyped;
exports.PartialOutwardDuffelBookingMethodTypeToJSON = PartialOutwardDuffelBookingMethodTypeToJSON;
/**
 *
 * @export
 */
exports.PartialOutwardDuffelBookingMethodType = {
    PartialOutwardDuffel: 'partialOutwardDuffel'
};
function PartialOutwardDuffelBookingMethodTypeFromJSON(json) {
    return PartialOutwardDuffelBookingMethodTypeFromJSONTyped(json, false);
}
function PartialOutwardDuffelBookingMethodTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
function PartialOutwardDuffelBookingMethodTypeToJSON(value) {
    return value;
}
