"use strict";
/* tslint:disable */
/* eslint-disable */
/**
 * RouteZero API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
Object.defineProperty(exports, "__esModule", { value: true });
exports.instanceOfBookingPricing = instanceOfBookingPricing;
exports.BookingPricingFromJSON = BookingPricingFromJSON;
exports.BookingPricingFromJSONTyped = BookingPricingFromJSONTyped;
exports.BookingPricingToJSON = BookingPricingToJSON;
var BookingLineItem_1 = require("./BookingLineItem");
var Price_1 = require("./Price");
/**
 * Check if a given object implements the BookingPricing interface.
 */
function instanceOfBookingPricing(value) {
    var isInstance = true;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "fullPrice" in value;
    isInstance = isInstance && "lineItems" in value;
    return isInstance;
}
function BookingPricingFromJSON(json) {
    return BookingPricingFromJSONTyped(json, false);
}
function BookingPricingFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'price': (0, Price_1.PriceFromJSON)(json['price']),
        'fullPrice': (0, Price_1.PriceFromJSON)(json['fullPrice']),
        'lineItems': (json['lineItems'].map(BookingLineItem_1.BookingLineItemFromJSON)),
    };
}
function BookingPricingToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'price': (0, Price_1.PriceToJSON)(value.price),
        'fullPrice': (0, Price_1.PriceToJSON)(value.fullPrice),
        'lineItems': (value.lineItems.map(BookingLineItem_1.BookingLineItemToJSON)),
    };
}
